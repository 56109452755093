import React from "react"
import { Helmet } from "react-helmet"
import { ContactForm, Layout } from "../../../components"
import { Medailon, PaddingWrapper, H2 } from "@igloonet-web/shared-ui"
import Logo from "../../../images/reference/smart-lashes/logo.png"
import Top from "../../../images/reference/smart-lashes/top.png"
import Zdanovich from "../../../images/reference/smart-lashes/zdanovich.png"
import ReferenceImageText from "../../../components/reference-detail/reference-image-text"
import ReferenceHeader from "../../../components/reference-detail/reference-header"

const SmartLashes = () => (
  <Layout>
    <Helmet>
      <title>Díky růstu až k expanzi na západní trhy | igloonet.cz</title>
      <meta
        name="description"
        content="„Spolupráce s igloonetem nám pomohla v&nbsp;růstu tržeb i&nbsp;celého e-shopu, který by bez správně nastavených PPC kampaní nebyl tak dynamický,“ Aleksander Zdanovich, majitel."
      />
    </Helmet>

    <ReferenceHeader
      companyName="SMART LASHES"
      heading="Díky kontinuálnímu růstu až k expanzi na západní trhy"
      date="jaro 2017 – současnost"
    />

    <ReferenceImageText
      right
      img={Top}
      eager
      alt=""
      logo={Logo}
      logoAlt="logo společnosti Smart Lashes"
      link="//smartlashes.cz/"
    >
      <p>
        Smart Lashes je jedničkou na českém a slovenském trhu umělých řas a
        produktů s nimi spojených. Všechny své výrobky navrhují a posléze
        testují ve vlastních salonech, ručí tak za 100% kvalitu. Zároveň vedou
        kurzy a školí lash specialisty.
      </p>
    </ReferenceImageText>

    <ReferenceImageText>
      <PaddingWrapper>
        <H2>Rozsah projektu</H2>
        <p className="text-center">
          Se Smart Lashes spolupracujeme od jara roku 2017, kdy jsme převzali
          výkonnostní kampaně na Googlu a Seznamu. Za prvních 6 měsíců jsme v
          Česku a na Slovensku zvýšili tržby o 120 %.
        </p>
      </PaddingWrapper>

      <PaddingWrapper>
        <p>
          Spokojený klient nám svěřil i&nbsp;správu kampaní na FB/IG a
          e&#x2011;maling, pravidelně také konzultujeme marketingovou
          i&nbsp;byznysovou stránku. Více než 4 roky tedy společně rosteme a
          celou firmu posouváme dál.
        </p>
      </PaddingWrapper>
      <PaddingWrapper>
        <H2>Další postup</H2>
        <p>V současné době pomáháme s expanzí do Německa a Rakouska.</p>
      </PaddingWrapper>
    </ReferenceImageText>

    <PaddingWrapper>
      <Medailon
        personName="Aleksander Zdanovich"
        position="majitel"
        img={Zdanovich}
      >
        <p>
          Spolupráce s igloonetem nám pomohla v růstu tržeb i celého e-shopu,
          který by bez správně nastavených PPC kampaní nebyl tak dynamický.
        </p>
      </Medailon>
    </PaddingWrapper>
    <ContactForm contact="adam" />
  </Layout>
)

export default SmartLashes
